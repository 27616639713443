@import 'variables_color_dark_theme.scss';

// fonts

$main-font: 'Lato', sans-serif;
$icon-font: FontAwesome;
$monospace-font: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;

// font sizes and metrics

$factor: 1.25;
$base: 14px;
$base1: round($base * $factor); // 17px
$base2: round($base1 * $factor); // 22px
$base3: round($base2 * $factor); // 27px
$base4: round($base3 * $factor); // 34px
$base5: round($base4 * $factor); // 43px
$base6: round($base5 * $factor); // 54px
$base7: round($base6 * $factor); // 67px
$base-1: round($base / $factor); // 11px
$base-2: round($base-1 / $factor); // 9px
$base-3: floor($base-2 / $factor); // 6px

$block-text-margin-b: 1.2em;

// viewport sizes

$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;

// shadows

@mixin box-shadow {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
}
@mixin box-shadow-bigger {
  box-shadow: 0 2px 10px $box-shadow-color;
}

// metrics and behavior

$left-col-width: 194px;
$header-height: 66px;
$mobile-header-height: 56px;

$header-nav-height: 80px;
$header-nav-height-mobile: 56px;
$team-selectbar-width: 49px;

@mixin border-radius {
  border-radius: 4px;
}

@mixin border {
  @include border-radius;
  border: 1px solid $gray-light;
}

@mixin panel-padding {
  padding: $base-1 $base;
}

@mixin prevent-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

@mixin transition {
  -webkit-transition: 0.15s;
  -moz-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}

@mixin description-font {
  font-size: 1.15em;
  line-height: 1.6em;
}

@mixin display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin position-sticky {
  position: -webkit-sticky;
  position: sticky;
}
@mixin text-input {
  background-image: none;
  margin: 0 7px 0.6em 0;
  box-shadow: none !important;
  border-radius: 6px;
}

@mixin text-input-focus {
  border-color: $blue-light;
  box-shadow: none;
  outline: none !important;
}

@mixin dropdown-menu-styles {
  @include transition;
  box-shadow: 0 2px 10px $box-shadow-color;
  transform-origin: top;
  padding: 5px 0;
  visibility: hidden;
  display: block;
  transform: scale3d(1, 1, 1);
  opacity: 0;
  max-height: 70vh;
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
  z-index: 1000;
  min-width: 160px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  background-color: $bg-hierarchy1;
  border: 1px solid $border-box-color;
  border-radius: 4px;
}

@mixin base-button-styles {
  display: inline-block;
  font-family: $main-font;
  line-height: 2.6em;
  max-width: 100%;
  padding: 0 1.15em;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  border: none;
  min-height: 2.6em;
  outline: none;
  font-size: $base;
  font-weight: bold;
  border-radius: 6px;
  -webkit-appearance: none;
  text-decoration: none !important;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  @include transition;
}

@mixin select-criteria-for-input-styles {
  position: relative;
  padding: 0 1em;
  border-radius: 4px 0 0 4px;
  line-height: 2.4em;

  &:hover {
    opacity: 0.9;
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top-color: $dark-gray;
    right: 9px;
    top: 45%;
  }

  select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 1em;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background: transparent;
    cursor: pointer;
    color: inherit;
  }
}

@mixin drop_target_indicator {
  display: block;
  position: relative;
  content: '';
  height: 53px;
  margin: 4px 0;
  border-radius: 2px;
  background-color: transparentize($gray, 0.5);
}

@mixin draggable-indicator {
  content: '\f142\f142';
  font-family: FontAwesome;
  color: darken($gray-light, 9%);
  position: absolute;
  letter-spacing: 2px;
  cursor: move;
}

@mixin paragraphs-inside-rich-text {
  p {
    margin-bottom: $block-text-margin-b;

    // hack to avoid Trix crashing
    &:empty {
      margin: 0;
      height: 0;
    }
  }
}

@mixin full-width {
  width: 100% !important;
  max-width: 100% !important;
}
