[type='text'],
[type='tel'],
[type='email'],
[type='password'],
[type='number'],
[type='url'],
textarea {
  color: inherit;
  background-color: $bg-textfields;
  border: 1px solid $border-textfield;
}

input {
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &[disabled] {
    cursor: not-allowed !important;
    pointer-events: initial !important;
  }
  // Prevent browser autofill color and background
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s,
      color 5000s ease-in-out 0s !important;
  }
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='url'],
textarea {
  width: 100%;
  max-width: 400px;
}

[type='text'],
[type='tel'],
[type='email'],
[type='password'],
[type='number'],
[type='url'],
textarea {
  @include text-input;
  @include transition;
  -webkit-appearance: none;
  padding: 0.58em 9px;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 25px 16px;

  &:focus {
    @include text-input-focus;
    &[required]:invalid {
      border-color: $red;
    }
  }

  &::placeholder {
    color: $color-hierarchy3;
  }

  &[disabled] {
    opacity: 0.6;
  }

  &.narrow {
    width: 200px;
  }
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
  &[disabled] {
    & + label {
      cursor: default;
      opacity: 0.6;
      &:hover {
        color: inherit;
      }
    }
  }
}

textarea {
  padding: 13px 15px;
}

label {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: 4px;

  &:hover {
    color: inherit;
  }

  &[for] {
    cursor: pointer;
    &:hover {
      color: $blue;
    }
  }
  input[type='checkbox'],
  input[type='radio'] {
    margin-right: 3px;
  }
}
:checked + span {
  font-weight: 700;
}
