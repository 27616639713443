@import 'variables_color.scss';
/*
 * === SEMANTIC VARIABLES DARK THEME ===
*/
$blue: #00c4f5;

// Text color
$color-hierarchy1: $gray-light-3;
$color-hierarchy2: $gray;
$color-hierarchy3: $dark-gray;
$color-link: $blue-light;
$color-link-hover: $blue;

// Background color
$bg-hierarchy1: $sherpa-blue;
$bg-hierarchy2: $sherpa-blue-2;
$bg-hierarchy3: $sherpa-blue-3;

// Buttons
$btn-color: $white;
$btn-color--outlined: $white;
$btn-color--ghost: $color-link;
$btn-color--disabled: $color-hierarchy3;

$btn-bg: $blue;
$btn-bg--hover: $blue-light;
$btn-bg--focus: #1bbaff;
$btn-bg--hover-outlined: rgba(229, 234, 235, 0.055);
$btn-bg--focus-outlined: rgba(229, 234, 235, 0.04);

$btn-bg--hover-ghost: $sherpa-blue-2;
$btn-bg--focus-ghost: $sherpa-blue-3;

$btn-border-color: $sherpa-blue-3;

// Textfields and selects
$border-textfield: $dark-gray;
$bg-textfields: $sherpa-blue-2;

// Transparent Text Background
$dark-theme-bg-selection: rgba(0, 196, 245, 0.3);
$dark-theme-bg-target: rgba(0, 196, 245, 0.5);

// Border box
$border-box-color: $semi-transparent-gray !default;

// Box-shadow

$box-shadow-color: rgba(0, 0, 0, 0.7);

$bg-backdrops: rgba(9, 10, 11, 0.75);

// secondary colors
$purple: #b669d7;
$red: #bf5050;

@mixin button-colors-negative {
  color: $btn-color;
  background-color: $btn-bg;

  &:hover {
    color: $btn-color;
    background-color: $btn-bg--hover;
  }

  &:focus {
    color: $btn-color;
    background-color: $btn-bg--focus;
    box-shadow: 0 1px 4px rgba(200, 250, 250, 0.08);
  }

  &:disabled,
  &.disabled {
    color: $btn-color--disabled;
    background-color: $bg-hierarchy2 !important;
  }
}

@mixin button-outlined-colors-negative {
  background-color: transparent;
  border-color: $btn-border-color;
  color: $btn-color--outlined;

  // States (Inherits disabled appearance)
  &:hover,
  &:focus {
    color: $btn-color--outlined;
  }

  &:hover {
    background-color: $btn-bg--hover-outlined;
  }

  &:focus {
    background-color: $btn-bg--focus-outlined;
  }

  &:disabled,
  &.disabled {
    color: $btn-color--disabled;
  }
}

@mixin button-ghost-colors-negative {
  background-color: transparent;
  color: $btn-color--ghost;

  // States
  &:hover {
    background-color: $btn-bg--hover-ghost;
    color: $btn-color--ghost;
  }

  &:focus {
    color: $btn-color--ghost;
    background-color: $btn-bg--focus-ghost;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    background-color: transparent;
    color: $btn-color--disabled;
  }
}

@mixin pill-switch-colors-negative {
  border-color: transparent;
  color: $color-hierarchy2;
  background-color: $bg-hierarchy3;
}
