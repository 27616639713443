.editor-view {
  div {
    margin-bottom: $block-text-margin-b;
  }
}
.job-application-body,
.editor-view,
.gb-rich-txt {
  line-height: 1.7em;
  word-break: break-word;

  @include paragraphs-inside-rich-text;

  b,
  strong {
    font-weight: 600;
  }

  i:not(.fa),
  em {
    font-style: italic;
  }

  li:empty {
    display: none;
  }

  h1,
  h2 {
    font-size: $base2;
  }

  h2,
  h3,
  h4 {
    font-weight: 900;
    margin-bottom: 0.9em;
  }

  h3 {
    font-size: 1.3em !important;
  }

  h4 {
    font-size: 1.15em !important;
  }

  blockquote {
    padding: 10px 20px;
    margin: 0 0 $block-text-margin-b;
    font-size: inherit;
    border-left: 5px solid $gray-light-2;
    font-style: italic;
    color: $dark-gray;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 0 0 $block-text-margin-b;
  }

  figure {
    margin-bottom: $block-text-margin-b;
    text-align: center;

    iframe {
      width: 100%;
      max-width: 800px;
    }
  }

  figcaption {
    margin-top: -$block-text-margin-b;
    color: $gray;
    font-size: $base;
    line-height: 1.8em;
  }

  ul {
    li {
      list-style: disc;
    }
  }
  ol {
    li {
      list-style: decimal;
    }
  }
  ul,
  ol {
    margin-bottom: $block-text-margin-b;
    padding-left: 30px;

    li {
      margin-bottom: 0.5em;
    }
  }
  pre {
    font-family: $monospace-font;
    display: block;
    padding: 9.5px;
    margin: 0 0 ($block-text-margin-b * 1.5);
    font-size: 13px;
    color: $graphite;
    word-break: break-all;
    word-wrap: break-word;
    background-color: $gray-light-2;
    border: 1px solid $gray-light;
    border-radius: 4px;
  }
}
