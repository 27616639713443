/*
 * === DESCRIPTIVE VARIABLES ===
*/

// core colors
$red: #ff7777;

$sherpa-blue: #002e39;
$sherpa-blue-2: #0f3942;
$sherpa-blue-3: #1f434c;
$sherpa-blue-4: #005267;

$blue: #0093d3 !default;
$blue-light: #00c4f5;
$blue-light-2: #6bdaf5;

// grayscale colors
$graphite: #2b353b;
$dark-gray: #627884;
$gray: #a7b7be;
$gray-light: #d9e3e8; // former $silver
$gray-light-2: #ecf1f4; // former $light-gray
$gray-light-3: #f2f6f7; // former $lighter-gray

// secondary colors
$green: #47c27a !default;
$purple: #933db8 !default;
$white: #fff !default;
$orange: #f39c12 !default;
$yellow: #f4cc00 !default;
$light-yellow: #fcf9df !default;

// transparencies
$semi-transparent-black: rgba(0, 0, 0, 0.2);
$semi-transparent-gray: rgba(135, 153, 160, 0.25);

// external brands colors
$fb-blue: #4460a2;
$tw-blue: #56a8e2;
$linkedin-blue: #0077b5;

/*
 * === SEMANTIC VARIABLES DEFAULT THEME ===
*/

// Text color
$color-hierarchy1: $graphite !default;
$color-hierarchy2: $dark-gray !default;
$color-hierarchy3: $gray !default;
$color-link: $blue !default;
$color-link-hover: $sherpa-blue-4 !default;

// Background color
$bg-hierarchy1: $white !default;
$bg-hierarchy2: $gray-light-3 !default;
$bg-hierarchy3: $gray-light-2 !default;

// Buttons
$btn-color: $white !default;
$btn-color--outlined: $color-link !default;
$btn-color--ghost: $color-link !default;
$btn-color--disabled: $color-hierarchy3 !default;

$btn-bg: $blue-light !default;
$btn-bg--hover: $blue !default;
$btn-bg--focus: lighten($blue, 6%) !default;

$btn-bg--hover-outlined: rgba(
  43,
  53,
  59,
  0.055
) !default; // font color transparentized
$btn-bg--focus-outlined: rgba(
  43,
  53,
  59,
  0.04
) !default; // font color transparentized

$btn-bg--hover-ghost: rgba(
  0,
  147,
  211,
  0.055
) !default; // link color transparentized
$btn-bg--focus-ghost: rgba(
  0,
  147,
  211,
  0.04
) !default; // link color transparentized

$btn-border-color: $blue !default;

// Textfields and selects
$border-textfield: $gray !default;
$bg-textfields: $gray-light-3 !default;

// Border box
$border-box-color: $semi-transparent-gray !default;

// Box-shadow
$box-shadow-color: rgba(0, 0, 0, 0.25) !default;

@mixin button-colors {
  color: $btn-color;
  background-color: $btn-bg;

  // States
  &:hover,
  &:focus {
    color: $btn-color;
  }

  &:hover {
    background-color: $btn-bg--hover;
  }

  &:focus {
    background-color: $btn-bg--focus;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  }

  &:disabled,
  &.disabled {
    color: $btn-color--disabled;
    background-color: $bg-hierarchy2 !important;
    cursor: not-allowed;
  }
}

@mixin button-outlined-colors {
  border: 1px solid $btn-border-color;
  background-color: transparent;
  color: $btn-color--outlined;

  // States (Inherits disable appearance)
  &:hover,
  &:focus {
    color: $btn-color--outlined;
  }

  &:hover {
    background-color: $btn-bg--hover-outlined;
  }

  &:focus {
    background-color: $btn-bg--focus-outlined;
  }

  &:disabled,
  &.disabled {
    color: $btn-color--disabled;
  }
}

@mixin button-ghost-colors {
  background-color: transparent;
  padding: 0 0.35em;
  color: $btn-color--ghost;

  // States
  &:hover,
  &:focus {
    color: $btn-color--ghost;
  }

  &:hover {
    background-color: $btn-bg--hover-ghost;
  }

  &:focus {
    background-color: $btn-bg--focus-ghost;
  }

  &:disabled,
  &.disabled {
    background-color: transparent;
    color: $btn-color--disabled;
  }
}

@mixin pill-switch-colors {
  border-color: transparent;
  color: $color-hierarchy2;
  background-color: $bg-hierarchy2;
}