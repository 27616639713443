@import 'variables';

// Button tag reset.
// Use buttons instead links for actions. It is semantic and accesible.
button {
  -webkit-appearance: none;
  border: none;
  padding: 0;
}

// Base/main button (Hopefuly just one per view)
.gb-btn {
  @include base-button-styles;
  @include button-colors;

  // Icons
  .fa {
    color: inherit;
  }

  // Utilities
  &.block {
    display: block;
    width: 100%;
  }
  &.small {
    font-size: 12px;
  }
  &.big{
    font-size:18px;
  }
  &.bg-graphite {
    background-color: $graphite;
  }
  &.bg-red {
    background-color: $red;
  }
  &.red {
    color: $red;
  }
  &.green {
    color: $green;
  }
  &.full-width {
    padding-top: 1%;
    padding-bottom: 1%;
  }
}

// Button outlined (Second priority/hierarchy)
.gb-btn--outlined {
  @include button-outlined-colors;
  &.blue {
    color: $blue
  }
}

// Button ghost/link (Less important, last priority)
.gb-btn--ghost {
  @include button-ghost-colors;
}

.gb-btn--diabled-transparent {
  &:disabled,
  &.disabled {
    background-color: transparent;
  }
}

// Button spimer/loading for ajax feedback
@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-spinner {
  display: inline-block;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: $white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: donut-spin 1.2s linear infinite;
}
