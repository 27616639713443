@import 'styles/utilities.scss';
@import 'styles/dropdowns.scss';
@import 'styles/buttons.scss';


.bg {
  position: absolute;
  top: 66px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: visible;
  margin: 0;
  height: 100vh;
  animation: gradientAnimation 10s infinite linear;
  background: linear-gradient(225deg, #71A58B, #864dae, #205165, #4191cd, #71A58B, #864dae, #205165);
  background-size: 6000px 3000px; /* This makes the gradient larger to allow for the movement */
}

@keyframes gradientAnimation {
  0% {
    background-position: 3000px 0;
  }
  100% {
    background-position: -3000px 0;
  }
}

.bgimage {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.homebg {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}

svg text {
  font-family: Lato !important;
  font-weight: bold;
}

.insights-title {
  position: relative;
  top: -1px;
  left: -5px;
}

.responsiveBubble {
  height: 600px;
}

.seniorityFilters {
  margin-bottom: $base;
}

.loading {
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  font-size: 2em;
}

.lds-ripple {
  margin: 20px auto;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.gb-filters__pill {
  @include base-button-styles;
  margin-right: 0;
  border-radius: 0;

  &,
  & a {
    @include button-outlined-colors;
    @include pill-switch-colors;

    &:hover {
      background-color: $bg-hierarchy2;
      color: $btn-color--ghost;
    }
  }
  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .fa {
    margin-right: 5px;
  }
  .badge {
    padding: 0.4em 0.55em;
    background-color: $bg-hierarchy1;
    color: $color-hierarchy2;
    &:last-child {
      margin-left: 3px;
    }
  }
}
.gb-filters__pill.active,
.gb-filters__pill--active {
  &,
  & a {
    @include button-colors;
    cursor: default;
    &:hover,
    &:focus {
      background-color: $btn-bg;
    }
  }
}

@media screen and (max-width: 550px) {
  .responsiveBubble {
    height: 400px;
  }
  .seniorityFilters {
    justify-content: center;
    margin-bottom: 0;
  }
}
