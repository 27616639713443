@import 'variables.scss';

a {
  color: $color-link;
  @include transition;
  text-decoration: none;

  &:hover {
    color: $color-link-hover;
  }
}

:focus {
  outline: 1px dotted $blue-light;
  text-decoration: none;
}

@import 'buttons.scss';
@import 'grids.scss';
@import 'rich_text.scss';
@import 'tables.scss';
@import 'textfields.scss';

// Single-purpose, immutable styles

// text-align utilities

.text-left {
  text-align: left;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

// word wrap utilities

.break-word {
  word-break: break-word;
}

// text-decoration and transform utilities

.underline {
  text-decoration: underline;
}
.hover-underline:hover {
  text-decoration: underline;
}
.line-through {
  text-decoration: line-through;
}
.uppercase {
  text-transform: uppercase;
}
.small-caps {
  font-variant: small-caps;
}
.no-letter-spacing {
  letter-spacing: 0;
}

// float and clear utilities

.left {
  float: left;
}
.right {
  float: right;
}
.float-none {
  float: none;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.clear {
  clear: both;
}
.clear-left {
  clear: left;
}
.clear-right {
  clear: right;
}

// vertical-align utilities

.v-align-baseline {
  vertical-align: baseline !important;
}
.v-align-middle {
  vertical-align: middle;
}
.v-align-top {
  vertical-align: top;
}
.v-align-bottom {
  vertical-align: bottom;
}
.v-align-text-top {
  vertical-align: text-top;
}
.v-align-text-bottom {
  vertical-align: text-bottom;
}

// color utilities

.blue-light {
  color: $blue-light;
}
.sherpa-blue {
  color: $sherpa-blue;
}
.blue {
  color: $blue;
}
.graphite {
  color: $graphite !important;
}
.dark-gray {
  color: $dark-gray;
}
.gray {
  color: $gray;
}
.red {
  color: $red;
}
.light-yellow {
  color: $light-yellow;
}
.yellow {
  color: $yellow;
}
.light-gray {
  color: $gray-light-2;
}
.semi-light-gray,
.silver {
  color: $gray-light;
}
.green {
  color: $green;
}
.orange {
  color: $orange;
}
.white {
  color: $white !important;
}
.linkedin-blue {
  color: $linkedin-blue;
}
.tw-blue {
  color: $tw-blue;
}
.fb-blue {
  color: $fb-blue;
}
.transparent {
  color: transparent;
}
.semi-opaque-white {
  color: transparentize($white, 0.3);
}

// gradient utilities

.gradient-graphite {
  background-image: linear-gradient($graphite, lighten($graphite, 15%));
}

.gradient-blue {
  background-image: linear-gradient(darken($blue, 15%), $blue-light);
}

.gradient-white-to-transparent {
  background-image: linear-gradient(transparentize($white, 1), $white);
}

// hover utilities

.red:hover {
  color: $red;
}
.blue-light:hover {
  color: $blue-light;
}
.blue:hover {
  color: $blue;
}
.graphite:hover {
  color: $graphite;
}
.dark-gray:hover {
  color: $dark-gray;
}
.gray:hover {
  color: $gray;
}
.light-yellow:hover {
  color: $light-yellow;
}
.yellow:hover {
  color: $yellow;
}
.blue:hover {
  color: $blue;
}
.light-gray:hover {
  color: $gray-light-2;
}
.semi-light-gray:hover,
.silver:hover {
  color: $gray-light;
}
.green:hover {
  color: $green;
}
.orange:hover {
  color: $orange;
}
.white:hover {
  color: $white;
}
.linkedin-blue:hover {
  color: $linkedin-blue;
}
.tw-blue:hover {
  color: $tw-blue;
}
.fb-blue:hover {
  color: $fb-blue;
}

// Misc utilities

.location-flag {
  margin-right: 0.2em;
  top: 0.2em;
  width: 1.1em;
  height: 1.1em;
  display: inline-block;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
}

.external-link {
  &:after {
    content: ' \f08e';
    font-family: FontAwesome;
  }
}

.unread-indicator {
  font-size: 0.7em !important;
  color: $red !important;
}

.touch-action-manipulation {
  touch-action: manipulation;
}

.bar {
  margin: 0 3px;
  opacity: 0.4;
  &:before {
    content: '|';
  }
}

// z-index utilities

.level0 {
  z-index: 0;
}
.level1 {
  z-index: 10;
}
.level2 {
  z-index: 100;
}
.level3 {
  z-index: 1000;
}
.level4 {
  z-index: 10000;
}
.level5 {
  z-index: 100000;
}
.level6 {
  z-index: 1000000;
}

// background-color utilities

.bg-sherpa-blue {
  background-color: $sherpa-blue;
}
.bg-sherpa-blue-2 {
  background-color: $sherpa-blue-2;
  &.op6 {
    background-color: transparentize($sherpa-blue-2, 0.4);
  }
}
.bg-red {
  background-color: $red;
}
.bg-blue-light {
  background-color: $blue-light;
}
.bg-blue {
  background-color: $blue;
}
.bg-graphite {
  background-color: $graphite;
}
.bg-dark-gray {
  background-color: $dark-gray;
}
.bg-gray {
  background-color: $gray;
}
.bg-light-yellow {
  background-color: $light-yellow;
  color: $graphite;
}
.bg-yellow {
  background-color: $yellow;
}
.bg-light-gray {
  background-color: $gray-light-2;
}
.bg-lighter-gray {
  background-color: $gray-light-3;
}
.bg-semi-light-gray,
.bg-silver {
  background-color: $gray-light;
}
.bg-green {
  background-color: $green;
}
.bg-orange {
  background-color: $orange;
}
.bg-white {
  background-color: $white !important;
}
.bg-transparent {
  background-color: transparent;
}
.bg-transparent:hover {
  background-color: transparent;
}
.bg-ipro-blue{
  background-color: #003247;
}
.bg-linkedin-blue {
  background-color: $linkedin-blue !important;
}
.bg-tw-blue {
  background-color: $tw-blue;
}
.bg-fb-blue {
  background-color: $fb-blue;
}

// border-color utilities

.border-red {
  border-color: $red !important;
}
.border-blue-light {
  border-color: $blue-light !important;
}
.border-blue {
  border-color: $blue !important;
}
.border-graphite {
  border-color: $graphite !important;
}
.border-dark-gray {
  border-color: $dark-gray !important;
}
.border-sherpa-blue {
  border-color: $sherpa-blue !important;
}
.border-sherpa-blue-2 {
  border-color: $sherpa-blue-2 !important;
}
.border-gray {
  border-color: $gray !important;
}
.border-light-yellow {
  border-color: $light-yellow !important;
}
.border-yellow {
  border-color: $yellow !important;
}
.border-light-gray {
  border-color: $gray-light-2 !important;
}
.border-semi-light-gray,
.border-silver {
  border-color: $gray-light !important;
}
.border-green {
  border-color: $green !important;
}
.border-orange {
  border-color: $orange !important;
}
.border-white {
  border-color: $white !important;
}
.border-transparent {
  border-color: transparent !important;
}
.border-dashed {
  border-style: dashed !important;
}

// font size utilities

.h0,
.size6 {
  font-size: $base6;
  letter-spacing: -0.035em;
}
.h0,
.size5 {
  font-size: $base5;
  letter-spacing: -0.035em;
}
.h1,
.size4 {
  font-size: $base4;
  letter-spacing: -0.03em;
}
.h2,
.size3 {
  font-size: $base3;
  letter-spacing: -0.025em;
}
.h3,
.size2 {
  font-size: $base2;
  letter-spacing: -0.02em;
}
.h4,
.size1 {
  font-size: $base1;
  letter-spacing: -0.01em;
}
.h5,
.size0 {
  font-size: $base;
}
.h6,
.small,
.size-1 {
  font-size: $base-1;
}
.h7,
.size-2 {
  font-size: $base-2;
}
.h8,
.size-3 {
  font-size: $base-3;
}
.show-on-mobile,
.show-on-small-mobile,
.show-on-mobile-and-tablet,
.show-on-smallest-mobile {
  display: none !important;
}

// font utilities

.monospace {
  font-family: $monospace-font;
}

// Horizontal smartphone, vertical small tablets
@media screen and (max-width: 330px) {
  .hide-on-smallest-mobile {
    display: none;
  }
  .show-on-smallest-mobile {
    display: block !important;
  }
  span.show-on-smallest-mobile {
    display: inline !important;
  }
}
@media screen and (max-width: 550px) {
  .hide-on-phone,
  .hide-on-small-mobile {
    display: none;
  }
  .show-on-small-mobile {
    display: block !important;
  }
  span.show-on-small-mobile {
    display: inline !important;
  }
}
@media screen and (min-width: 992px) {
  .hide-on-desktop {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .hide-on-mobile {
    display: none !important;
  }
  .show-on-mobile {
    display: block !important;
  }
  span.show-on-mobile {
    display: inline !important;
  }
}
@media screen and (max-width: 1200px) {
  .hide-on-mobile-and-tablet {
    display: none !important;
  }
  .show-on-mobile-and-tablet {
    display: block !important;
  }
}
@media screen and (max-width: 550px) {
  .h0,
  .size6 {
    font-size: $base5;
    letter-spacing: -0.035em;
  }
  .h0,
  .size5 {
    font-size: $base4;
    letter-spacing: -0.035em;
  }
  .h1,
  .size4 {
    font-size: $base3;
    letter-spacing: -0.03em;
  }
  .h2,
  .size3 {
    font-size: $base2;
    letter-spacing: -0.025em;
  }
  .h3,
  .size2 {
    font-size: $base1;
    letter-spacing: -0.02em;
  }
  .h4,
  .size1 {
    font-size: $base1 - 1;
    letter-spacing: -0.01em;
  }
  .h5,
  .size0 {
    font-size: $base;
  }
  .h6,
  .small,
  .size-1 {
    font-size: $base-1;
  }
  .h7,
  .size-2 {
    font-size: $base-2;
  }
  .h8,
  .size-3 {
    font-size: $base-3;
  }
}

// height utilities

.heightb {
  height: $base;
}
.height1 {
  height: $base1;
}
.height2 {
  height: $base2;
}
.height3 {
  height: $base3;
}
.height4 {
  height: $base4;
}
.height5 {
  height: $base5;
}
.height6 {
  height: $base6;
}

// line height utilities

.lh6 {
  line-height: $base6;
}
.lh5 {
  line-height: $base5;
}
.lh4 {
  line-height: $base4;
}
.lh3 {
  line-height: $base3;
}
.lh2 {
  line-height: $base2;
}
.lh1 {
  line-height: $base1;
}
.lhb {
  line-height: $base;
}
.lh-1 {
  line-height: $base-1;
}
.lh-2 {
  line-height: $base-2;
}
.lh-3 {
  line-height: $base-3;
}
.lh0 {
  line-height: 0;
}
.lh-single {
  line-height: 1;
}

.min-height-auto {
  min-height: auto;
}
.min-width-auto {
  min-width: auto;
}

// temp bootstrap override

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: inherit;
}

// margin utilities

.m0 {
  margin: 0;
}
.mt0 {
  margin-top: 0;
}
.mr0 {
  margin-right: 0;
}
.mb0 {
  margin-bottom: 0;
}
.ml0 {
  margin-left: 0;
}
.mx0 {
  margin-left: 0;
  margin-right: 0;
}
.my0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-3 {
  margin: $base-3;
}
.mt-3 {
  margin-top: $base-3;
}
.mr-3 {
  margin-right: $base-3;
}
.mb-3 {
  margin-bottom: $base-3;
}
.ml-3 {
  margin-left: $base-3;
}
.mx-3 {
  margin-left: $base-3;
  margin-right: $base-3;
}
.my-3 {
  margin-top: $base-3;
  margin-bottom: $base-3;
}

.m-2 {
  margin: $base-2;
}
.mt-2 {
  margin-top: $base-2;
}
.mr-2 {
  margin-right: $base-2;
}
.mb-2 {
  margin-bottom: $base-2;
}
.ml-2 {
  margin-left: $base-2;
}
.mx-2 {
  margin-left: $base-2;
  margin-right: $base-2;
}
.my-2 {
  margin-top: $base-2;
  margin-bottom: $base-2;
}

.m-1 {
  margin: $base-1;
}
.mt-1 {
  margin-top: $base-1;
}
.mr-1 {
  margin-right: $base-1;
}
.mb-1 {
  margin-bottom: $base-1;
}
.ml-1 {
  margin-left: $base-1;
}
.mx-1 {
  margin-left: $base-1;
  margin-right: $base-1;
}
.my-1 {
  margin-top: $base-1;
  margin-bottom: $base-1;
}

.mb {
  margin: $base;
}
.mtb {
  margin-top: $base;
}
.mrb {
  margin-right: $base;
}
.mbb {
  margin-bottom: $base;
}
.mlb {
  margin-left: $base;
}
.mxb {
  margin-left: $base;
  margin-right: $base;
}
.myb {
  margin-top: $base;
  margin-bottom: $base;
}

.m1 {
  margin: $base1;
}
.mt1 {
  margin-top: $base1;
}
.mr1 {
  margin-right: $base1;
}
.mb1 {
  margin-bottom: $base1;
}
.ml1 {
  margin-left: $base1;
}
.mx1 {
  margin-left: $base1;
  margin-right: $base1;
}
.my1 {
  margin-top: $base1;
  margin-bottom: $base1;
}

.m2 {
  margin: $base2;
}
.mt2 {
  margin-top: $base2;
}
.mr2 {
  margin-right: $base2;
}
.mb2 {
  margin-bottom: $base2;
}
.ml2 {
  margin-left: $base2;
}
.mx2 {
  margin-left: $base2;
  margin-right: $base2;
}
.my2 {
  margin-top: $base2;
  margin-bottom: $base2;
}

.m3 {
  margin: $base3;
}
.mt3 {
  margin-top: $base3;
}
.mr3 {
  margin-right: $base3;
}
.mb3 {
  margin-bottom: $base3;
}
.ml3 {
  margin-left: $base3;
}
.mx3 {
  margin-left: $base3;
  margin-right: $base3;
}
.my3 {
  margin-top: $base3;
  margin-bottom: $base3;
}

.m4 {
  margin: $base4;
}
.mt4 {
  margin-top: $base4;
}
.mr4 {
  margin-right: $base4;
}
.mb4 {
  margin-bottom: $base4;
}
.ml4 {
  margin-left: $base4;
}
.mx4 {
  margin-left: $base4;
  margin-right: $base4;
}
.my4 {
  margin-top: $base4;
  margin-bottom: $base4;
}

.m5 {
  margin: $base5;
}
.mt5 {
  margin-top: $base5;
}
.mr5 {
  margin-right: $base5;
}
.mb5 {
  margin-bottom: $base5;
}
.ml5 {
  margin-left: $base5;
}
.mx5 {
  margin-left: $base5;
  margin-right: $base5;
}
.my5 {
  margin-top: $base5;
  margin-bottom: $base5;
}

.m6 {
  margin: $base6;
}
.mt6 {
  margin-top: $base6;
}
.mr6 {
  margin-right: $base6;
}
.mb6 {
  margin-bottom: $base6;
}
.ml6 {
  margin-left: $base6;
}
.mx6 {
  margin-left: $base6;
  margin-right: $base6;
}
.my6 {
  margin-top: $base6;
  margin-bottom: $base6;
}

// padding utilities

.p0 {
  padding: 0;
}
.pt0 {
  padding-top: 0;
}
.pr0 {
  padding-right: 0;
}
.pb0 {
  padding-bottom: 0;
}
.pl0 {
  padding-left: 0;
}
.px0 {
  padding-left: 0;
  padding-right: 0;
}
.py0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-2 {
  padding: $base-2;
}
.pt-2 {
  padding-top: $base-2;
}
.pr-2 {
  padding-right: $base-2;
}
.pb-2 {
  padding-bottom: $base-2;
}
.pl-2 {
  padding-left: $base-2;
}
.px-2 {
  padding-left: $base-2;
  padding-right: $base-2;
}
.py-2 {
  padding-top: $base-2;
  padding-bottom: $base-2;
}

.p-3 {
  padding: $base-3;
}
.pt-3 {
  padding-top: $base-3;
}
.pr-3 {
  padding-right: $base-3;
}
.pb-3 {
  padding-bottom: $base-3;
}
.pl-3 {
  padding-left: $base-3;
}
.px-3 {
  padding-left: $base-3;
  padding-right: $base-3;
}
.py-3 {
  padding-top: $base-3;
  padding-bottom: $base-3;
}

.p-1 {
  padding: $base-1;
}
.pt-1 {
  padding-top: $base-1;
}
.pr-1 {
  padding-right: $base-1;
}
.pb-1 {
  padding-bottom: $base-1;
}
.pl-1 {
  padding-left: $base-1;
}
.px-1 {
  padding-left: $base-1;
  padding-right: $base-1;
}
.py-1 {
  padding-top: $base-1;
  padding-bottom: $base-1;
}

.pb {
  padding: $base;
}
.ptb {
  padding-top: $base;
}
.prb {
  padding-right: $base;
}
.pbb {
  padding-bottom: $base;
}
.plb {
  padding-left: $base;
}
.pxb {
  padding-left: $base;
  padding-right: $base;
}
.pyb {
  padding-top: $base;
  padding-bottom: $base;
}

.p1 {
  padding: $base1;
}
.pt1 {
  padding-top: $base1;
}
.pr1 {
  padding-right: $base1;
}
.pb1 {
  padding-bottom: $base1;
}
.pl1 {
  padding-left: $base1;
}
.px1 {
  padding-left: $base1;
  padding-right: $base1;
}
.py1 {
  padding-top: $base1;
  padding-bottom: $base1;
}

.p2 {
  padding: $base2;
}
.pt2 {
  padding-top: $base2;
}
.pr2 {
  padding-right: $base2;
}
.pb2 {
  padding-bottom: $base2;
}
.pl2 {
  padding-left: $base2;
}
.px2 {
  padding-left: $base2;
  padding-right: $base2;
}
.py2 {
  padding-top: $base2;
  padding-bottom: $base2;
}

.p3 {
  padding: $base3;
}
.pt3 {
  padding-top: $base3;
}
.pr3 {
  padding-right: $base3;
}
.pb3 {
  padding-bottom: $base3;
}
.pl3 {
  padding-left: $base3;
}
.px3 {
  padding-left: $base3;
  padding-right: $base3;
}
.py3 {
  padding-top: $base3;
  padding-bottom: $base3;
}

.p4 {
  padding: $base4;
}
.pt4 {
  padding-top: $base4;
}
.pr4 {
  padding-right: $base4;
}
.pb4 {
  padding-bottom: $base4;
}
.pl4 {
  padding-left: $base4;
}
.px4 {
  padding-left: $base4;
  padding-right: $base4;
}
.py4 {
  padding-top: $base4;
  padding-bottom: $base4;
}

.p5 {
  padding: $base5;
}
.pt5 {
  padding-top: $base5;
}
.pr5 {
  padding-right: $base5;
}
.pb5  {
  padding-bottom: $base5;
}
.pl5 {
  padding-left: $base5;
}
.px5 {
  padding-left: $base5;
  padding-right: $base5;
}
.py5 {
  padding-top: $base5;
  padding-bottom: $base5;
}

.p6 {
  padding: $base6;
}
.pt6 {
  padding-top: $base6;
}
.pr6 {
  padding-right: $base6;
}
.pb6  {
  padding-bottom: $base6;
}
.pl6 {
  padding-left: $base6;
}
.px6 {
  padding-left: $base6;
  padding-right: $base6;
}
.py6 {
  padding-top: $base6;
  padding-bottom: $base6;
}

// display utilities

.block,
.show {
  display: block;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block !important;
}
.hide,
.hidden {
  display: none !important;
}
.fade {
  display: none;
}

.fake-hidden {
  position: absolute;
  visibility: hidden;
}

// border utilities

.border {
  border-width: 1px;
  border-style: solid;
}
.no-border {
  border: 0 !important;
}
.border-top-none {
  border-top: 0;
}
.border-right-none,
.no-right-border {
  border-right: 0 !important;
}
.border-bottom-none {
  border-bottom: 0;
}
.border-left-none,
.no-left-border {
  border-left: 0 !important;
}
.border-radius {
  border-radius: 4px;
}
.border-radius2x {
  border-radius: 8px;
}
.no-border-radius {
  border-radius: 0 !important;
}
.border-radius-top {
  border-radius: 4px 4px 0 0;
}
.border-radius-bottom {
  border-radius: 0 0 4px 4px;
}
.boder-radius-1 {
  border-radius: 1em;
}

.border-top {
  border-top: 1px solid;
}
.border-right {
  border-right: 1px solid;
}
.border-bottom {
  border-bottom: 1px solid;
}
.border-left {
  border-left: 1px solid;
}

// outline utilities

.no-outline {
  outline: none;
  &:focus,
  &:active {
    outline: none;
  }
}

// box-shadow utilities

.no-box-shadow {
  box-shadow: none;
}

.box-shadow {
  @include box-shadow;
}

.box-shadow-bigger {
  @include box-shadow-bigger;
}

// width and height utilities. For everything else, use grids
.full-width {
  @include full-width;
}
.half-width {
  width: 50%;
}
.max-width-half {
  max-width: 50%;
}
.max-width-75 {
  max-width: 75%;
}
.third-width {
  width: 33%;
}
.full-height {
  height: 100%;
  max-height: 100%;
}
.one-fifth-width {
  width: 20%;
}

// whitespace utilities

.nowrap {
  white-space: nowrap;
}
.white-space-pre-line {
  white-space: pre-line;
}

// text-ellipsis and overflow utilities

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.overflow-auto {
  overflow: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}

// font-weight utilities

.w900 {
  font-weight: 900;
}
.w700,
.bold {
  font-weight: 700;
}
.w400,
.normal {
  font-weight: 400;
}
.w300,
.light {
  font-weight: 300;
}
.w100 {
  font-weight: 100;
}

// font-style utilities

.italic {
  font-style: italic;
}

// flexbox utilities

.flex {
  display: flex;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-v-centered,
.align-content-center {
  align-items: center;
}
.flex-v-baseline {
  align-items: baseline;
}
.flex-column {
  flex-direction: column;
}

.flex-grow1 {
  flex-grow: 1;
}

.flex-grow2 {
  flex-grow: 2;
}
.flex-shrink0 {
  flex-shrink: 0;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.align-items-flex-start {
  align-items: flex-start;
}
.align-items-flex-end {
  align-items: flex-end;
}
.align-self-flext-start {
  align-self: flex-start;
}
.align-content-baseline {
  align-items: baseline;
}

// table utilities

.fixed-width {
  table-layout: fixed;
}

// position utilities

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.static {
  position: static;
}
.sticky {
  @include position-sticky;
}
.top {
  top: 0;
}
.right {
  right: 0;
}
.bottom {
  bottom: 0;
}
.left {
  left: 0;
}

.realign-icon {
  position: relative;
  top: -0.1em;
  &.realign-icon-mr {
    margin-right: 3px;
  }
}

// cursor utilities

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.cursor-help {
  cursor: help;
}
.cursor-text {
  cursor: text;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

// Interaction utilities

.prevent-selection {
  @include prevent-selection;
}

.no-pointer-events {
  pointer-events: none;
}

.appearing {
  opacity: 0;
  transition: opacity 0.4s;
}
.opacity-half,
.appearing-transitioning {
  opacity: 0.45;
}
.appearing-done {
  opacity: 1;
}

.transition {
  transition: all 0.2s ease-in-out;
}

.rotate180 {
  transform: rotate(180deg);
}

// list style utilities

.bullets,
.bullets li {
  list-style: disc;
}

.no-list-style,
.no-list-style li {
  list-style: none;
}

.check-list {
  li {
    position: relative;
    padding-left: 1.4em;
    &:before {
      position: relative;
      font-family: $icon-font;
      color: $green;
      content: '\f00c';
      left: -0.25em;
    }
  }
}

// avatar or image utilities

.size16 {
  width: 16px;
  height: 16px;
}
.size24 {
  width: 24px;
  height: 24px;
}
.size32 {
  width: 32px;
  height: 32px;
}
.size48 {
  width: 48px;
  height: 48px;
}
.size64 {
  width: 64px;
  height: 64px;
}
.size72 {
  width: 72px;
  height: 72px;
}
.round {
  border-radius: 50%;
}

// iframe utilities

.iframe-container {
  position: relative;
  width: 100%;
  max-width: 720px;
  height: 0;
  padding-top: 56.5%; // ratio almost 16:9
  margin-bottom: 2em;
  &.max-480 {
    max-width: 480px;
    padding-top: 42%;
  }

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 550px) {
  .iframe-container {
    &.max-480 {
      padding-top: 60%;
    }
  }
}

// Form utilities
.select-container {
  position: relative;
  min-width: 200px;
  height: 3.4em;
  border: 1px solid $gray;
  line-height: 2.8em;
  border-radius: 4px;

  select {
    position: absolute;
    font-family: $main-font;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0;
    padding: 0 1em;
    background: transparent;
    -webkit-appearance: none;
    z-index: 1;
    font-weight: 700;
    color: inherit;
    cursor: pointer;

    // IE 10+ hack
    &::-ms-expand {
      display: none;
    }
    &:focus {
      outline: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 1em;
    top: 48%;
    height: 0;
    width: 0;
    border: 5px solid transparent;
    border-top-color: $gray;
  }
}

.select-container--shorter {
  height: 2.8em;
  line-height: 2.2em;
}

.select-container--mini {
  max-width: 80px;
}
.select-container--jumbo {
  top: 13px;
  min-width: 150px;

  select {
    padding-left: 0.5em;
    font-size: 22px;
  }
}

// Typography utilities

.epigraph {
  letter-spacing: 0.2em;
  color: $color-hierarchy2;
  font-weight: bold;
  text-transform: uppercase;
}

// Animation utilities

@keyframes subtle-blink {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.subtle-blink {
  animation: subtle-blink 0.5s linear infinite alternate;
}
