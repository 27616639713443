select:invalid[multiple] {
  margin-top: 15px !important;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.dropdown {
  position: relative;

  &.dropdown-msg-template {
    display: flex;

    &.open {
      > button,
      button:hover {
        background-color: $bg-hierarchy2;
      }
    }

    > button {
      @include transition;

      i {
        margin-right: 4px;
      }

      &:hover {
        background-color: $bg-hierarchy2;
      }
    }

    li {
      a {
        @include transition;

        &:hover {
          background-color: $bg-hierarchy2;
          text-decoration: none;
        }
      }
    }

    .dropdown-menu {
      padding: 41px 0 0;
      width: 380px;
      max-width: 96vw;
    }
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup,
.dropdown {
  &.dropdown-msg-template {
    .dropdown-menu {
      padding: 0;
      width: 380px;
      max-width: 96vw;
    }
  }
}
.dropdown-menu {
  @include dropdown-menu-styles;

  &.dropdown-menu-wide {
    min-width: 300px;
  }

  &.multiselect-container {
    padding-top: 0;

    .glyphicon {
      opacity: 0.5;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .glyphicon-search {
      &:before {
        content: '\f002';
      }
    }

    .glyphicon-remove-circle {
      &:before {
        content: '\f00d';
      }
    }

    > .active {
      > a {
        color: $dark-gray;
        font-weight: bold;
      }
    }
    > li {
      > a {
        > label {
          padding: 3px 5px 3px 20px;

          > input[type='checkbox'] {
            position: absolute;
            margin-left: -20px;
          }
        }
      }
    }
    .btn {
      border-radius: 0;
      position: absolute;
      right: 0;
      line-height: 34px;
      color: inherit;
      background: transparent;
      box-shadow: none;
      padding: 0 12px;
    }
    .input-group {
      margin: 0;
      border-bottom: 1px solid $border-box-color;
    }
    .input-group-addon {
      position: absolute;
      line-height: 2.5em;
      left: 17px;
    }
    input.multiselect-search {
      margin: 0;
      border: 0;
      padding-left: 2.8em;
      padding-right: 2em;
    }
    .multiselect-item {
      &.filter {
        border-bottom: 1px solid $border-box-color;
      }
    }
  }
  > div > li,
  > li {
    > button,
    > span,
    > a {
      display: block;
      clear: both;
      white-space: nowrap;
      width: 100%;
      text-align: left;

      &:focus,
      &:hover {
        background-color: $bg-hierarchy2;
        color: $color-hierarchy1;
      }
    }

    > button,
    > span,
    > a,
    > input[type='button'] {
      padding: 0.5em 1.4em;
      color: $color-hierarchy1;
      background-color: transparent;
      cursor: pointer;
      .fa {
        width: 1.7em;
      }
    }
  }

  > .disabled {
    > a,
    > a:hover {
      cursor: not-allowed;
      color: $gray;
    }
  }

  .divider {
    background-color: $border-box-color;
    opacity: 0.5;
    margin: 7px 0;
    height: 1px;
  }
}

.dropdown-menu-right,
.dropdown-right-aligned .dropdown-menu {
  right: 0;
  left: auto;
}

.dropdown-toggle {
  display: inline-block;
  &.gb-btn {
    box-shadow: none;
  }
}

.dropup {
  .dropdown-menu {
    transform-origin: bottom;
    box-shadow: 0 0 8px $semi-transparent-black;
  }
}

.open {
  > .dropdown-toggle,
  > .dropdown-toggle:hover {
    background-color: transparentize($gray, 0.7);
  }
  > .dropdown-menu {
    display: block;
    visibility: visible;
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

.dropdown-filter {
  width: 25%;
  padding: 0 0.3em;

  .multiselect {
    .caret {
      display: none;
    }

    &:after {
      content: '\f0d7';
      font-family: $icon-font;
      margin-left: 0.4em;
    }
  }
}

@media screen and (max-width: 550px) {
  .username {
    .dropdown-menu-right,
    .dropdown-right-aligned .dropdown-menu {
      position: fixed;
      right: 0;
      left: 0;
    }
  }
}

@media screen and (min-width: 551px) {
  select:invalid {
    height: 0px !important;
    opacity: 0 !important;
    position: absolute !important;
    display: flex !important;
  }
}
