.list {
  width: 100%;
  margin: 20px 0;

  tr.odd {
    background-color: $bg-hierarchy2;
  }

  tr {
    &.deleted {
      text-decoration: line-through;
    }
  }

  tbody {
    tr {
      &.hover:hover,
      &:hover {
        background-color: $bg-hierarchy3;
      }
    }
  }

  tr.separator-pending {
    td {
      background-color: $bg-hierarchy3;
    }
  }

  td {
    padding: 14px 10px 14px 10px;
    word-wrap: break-word;
    vertical-align: middle;
  }

  th {
    padding: 14px 10px 14px 10px;
    box-shadow: 0 1px 0 $border-box-color;
    text-align: left;
    white-space: nowrap;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    font-weight: 700;
  }
}
